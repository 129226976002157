var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[(_vm.initialLoading)?[_c('div',{staticClass:"card-body"},[_c('app-loading')],1)]:[_c('div',{ref:"comment_box",staticClass:"card-body p-1",attrs:{"id":"comment-box"}},[(_vm.loading)?[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})]:_vm._e(),_c('div',{ref:"checker",staticClass:"checker"}),_vm._l((_vm.instructionComments),function(comment){return [_c('div',{staticClass:"row mx-2 my-1",class:{
            'justify-content-start': _vm.user.id != comment.createdById,
            'justify-content-end': _vm.user.id == comment.createdById,
        }},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card",class:{
             'alert-secondary': _vm.user.id != comment.createdById,
            'alert-info': _vm.user.id == comment.createdById,
            }},[_c('p',{staticClass:"my-0 px-2 py-2 text-left font-weight-bold"},[_vm._v(_vm._s(comment.createdBy))]),_c('div',{staticClass:"card-body px-3 py-1 text-left"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.translateCommentIntoHtml(comment.comment))}}),_c('div',[_c('p',{staticClass:"text-right mb-0"},[_vm._v(_vm._s(comment.created_at))])])])])])])]})],2),_c('div',{staticClass:"card-footer"},[(!_vm.vesselResponse.cancelled_at)?[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendComment.apply(null, arguments)}}},[_c('div',{staticClass:"row no-gutters align-items-center"},[_c('div',{staticClass:"col-10"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userComment.comment),expression:"userComment.comment"}],ref:"user-comment-area",staticClass:"form-control form-control-sm comment-area",attrs:{"type":"text","placeholder":"Enter Comment Here","name":"","id":"comment-text-area"},domProps:{"value":(_vm.userComment.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userComment, "comment", $event.target.value)}}})]),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"e-btn e-btn-blue e-btn",class:_vm.userComment.comment === '' ? 'e-btn-blue-disabled' : '',attrs:{"type":"submit","disabled":_vm.userComment.comment === ''}},[_c('font-awesome-icon',{staticClass:"mr-1 force-white-all",attrs:{"icon":"envelope"}}),_vm._v("Send ")],1)])])])]:[_vm._m(0)]],2)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center font-weight-bold my-0"},[_vm._v("You can't comment right now "),_c('br'),_vm._v(" This company instruction has been cancelled")])
}]

export { render, staticRenderFns }