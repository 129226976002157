<template>
    <div v-if="isShown">
      <template v-if="instruction_sub && !!instruction_sub.companyInstructions">

        <template v-if="!user.vessel_id">
        <table class="small e-tbl c-tbl-padded-left-10">
          <thead>
            <tr id="tableHeader">
              <th style="width: 20%">Target Ship</th>
              <th style="width: 15%">Type of instruction</th>
              <th style="width: 15%">Sent Date</th>
              <th style="width: 15%">Description</th>
              <th style="width: 15%">Job Done Status</th>
              <th style="width: 10%">Job Done</th>
              <th style="width: 10%">Accept</th>
              <th style="width: 10%">Deficiency</th>
              <th style="width: 10%">Action</th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="(instruction2, index) in instruction_sub.companyInstructions"  :class="setClassTree(index)">
              <td style="width: 20%">{{instruction2.related_party | toUpper}}</td>
              <td style="width: 15%">{{instruction2.instruction_type | toUpper}}</td>
              <td style="width: 15%">{{ instruction2.sent_at }}</td>
              <td style="width: 15%">{{instruction2.title | toUpper}}</td>
              <td style="width: 15%" class="align-middle text-white" :class="{
                  'bg-success font-weight-bolder': instruction2.sent_at && !instruction2._cancelled_at,
                  'bg-secondary font-weight-bolder': !instruction2.sent_at && !instruction2._cancelled_at,
                  'bg-danger font-weight-bolder': instruction2.cancelled_at,
                }">
                {{
                  instruction2.sent_at && !instruction2.cancelled_at ? 'SENT' :
                    !instruction2.sent_at && !instruction2.cancelled_at ? 'DRAFT' :
                      'CANCELLED'
                }}
              </td>
              <td style="width: 10%" class="align-middle">
                <template v-if="instruction2.sent_at">
                  <span class="font-weight-bolder"
                        :class="{'text-success' : instruction2.done > 0 }">{{ instruction2.done }}</span> /
                  <span class="font-weight-bolder">{{ instruction2.sent }}</span>
                </template>
              </td>
              <td style="width: 10%" class="align-middle">
                <template v-if="instruction2.sent_at">
                <span class="font-weight-bolder text-success">{{ instruction2.acknowledgement_count }}</span> /
                  <span class="font-weight-bolder">{{ instruction2.sent }}</span>
                </template>
              </td>


              <td style="width: 10%" class="align-middle">
                <template v-if="instruction2.sent_at">
                <span class="font-weight-bolder text-danger">{{ instruction2.def_count }}</span> /
                  <span class="font-weight-bolder">{{ instruction2.sent }}</span>
                </template>
              </td>

              <td style="width: 10%">
                <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click="triggerView(instruction2)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
          </template>


           <template v-else>

             <table class="small e-tbl c-tbl-padded-left-10">

               <tbody>
             <tr v-if="!!instruction2.vessel" v-for="(instruction2, index) in instruction_sub.companyInstructions">
               <td><button class="e-btn e-btn-blue-gray e-btn-block font-weight-bold">
                 {{instruction2.related_party | toUpper}}</button>
               </td>
               <td>{{instruction2.instruction_type}}</td>
               <td>{{instruction2.title}}</td>
               <td>{{instruction2.sent_at}}</td>
               <td>{{instruction2.created_by}}</td>
               <td>
                 <template v-if="!!instruction2.vessel">
                    <span class="font-weight-bolder" :class="{
                  'text-danger':instruction2.vessel.cancelled_at,
                  'text-574e4e':!instruction2.vessel.cancelled_at && instruction2.vessel.job_status_id ==1,
                  'text-056cb4':!instruction2.vessel.cancelled_at && instruction2.vessel.job_status_id ==2,
                  'text-42b983':!instruction2.vessel.cancelled_at && instruction2.vessel.job_status_id ==3,
                   }">{{instruction2.vessel.cancelled_at ? 'CANCELLED' : instruction2.vessel.job_status | toUpper}}</span>
                 </template>
               </td>
               <td>
                 <template v-if="!!instruction2.vessel">
                    <span  class="font-weight-bolder" :class="{
                   'text-danger':instruction2.vessel.cancelled_at,
                   'text-4c4c4c':!instruction2.vessel.cancelled_at && instruction2.vessel.acknowledgement_status_id ==1,
                   'text-042454':!instruction2.vessel.cancelled_at && instruction2.vessel.acknowledgement_status_id == 2,
                   'text-42b983':!instruction2.vessel.cancelled_at && instruction2.vessel.acknowledgement_status_id == 3,
                   'text-1b4f72':!instruction2.vessel.cancelled_at && instruction2.vessel.acknowledgement_status_id == 4,
                   }">{{instruction2.vessel.cancelled_at ? 'CANCELLED' : instruction2.vessel.acknowledgement_status | toUpper}}</span>
                 </template>
               </td>
               <td>
                 <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click="triggerView(instruction2)">
                   <font-awesome-icon icon="eye"/>
                   View
                 </button>
               </td>
             </tr>
             </tbody>
             </table>
           </template>



<!--        <hr style="border: 1px solid rgba(189,189,189,0.68)">-->
        <hr style="border-bottom: 2px solid rgb(100,100,100); margin-left: 7rem; width: calc(100% - 7rem);">
      </template>
      <template v-if="instruction_sub && !!instruction_sub.children">
        <template v-for="node in instruction_sub.children">
          <template v-if="node.companyInstructions.length > 0">
            <company-instruction-sub-tree  :node="node" :is-shown="isShown" @viewInstruction="node=>$emit('viewInstruction',node)">
            </company-instruction-sub-tree>
          </template>
        </template>
      </template>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name:"CompanyInstructionSubTree",
  props: {
    node: {
      type: Object | Array,
      default: null,
    },
    report:{
      type: Object | Array,
      default: null,
    },
    isShown:{
      type:Boolean,
      default:true,
    }
  },
  computed:{
    ...mapGetters(['user'])
  },
  data(){
    return {
      instruction_sub:null
    }
  },
  methods:{
    triggerView(instruction){
      this.$emit('viewInstruction',instruction)
    },
    setClassTree(index) {
      let classTree = 'tree';
      if (this.instruction_sub.companyInstructions.length === 1) {
        classTree = 'one-last-tree';
      } else if (this.instruction_sub.companyInstructions.length === (index + 1)) {
        classTree = 'fix-last-tree';
      }
      return classTree;
    }
  },
  mounted() {
   this.instruction_sub=this.node
  }
}
</script>
<style scoped>
  .c-tbl-padded-left-10 {
    margin-left: 5rem;
    width: calc(100% - 6rem);
  }
  td:nth-child(1) {
    width: 15%;
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 20%;
  }

  td:nth-child(5),
  td:nth-child(6){
    width: 10%;
  }
  td:nth-child(7){
    width: 8%;
  }

  .tree {
    margin: 0;
    line-height: 20px;
    border-left:2px solid rgb(100,100,100);

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .tree:before {
    box-sizing: border-box;
    margin:0;
    position:relative;
    height: 25px;
    width:33px; /* 35px is the original width, but border-left of class .tree pushes the element to the right by 2px, hence the less 2px from the original with */
    color:white;
    border-bottom: 2px solid rgb(100,100,100);
    content:"";
    display:flex;
    z-index: -100;
  }

  .last-tree {
    border-left:none;
  }

  .fix-last-tree:before {
    box-sizing: border-box;
    border-left: 2px solid rgb(100, 100, 100);
    position: absolute;
    height: 25px;
    width: 35px;
    color: white;
    border-bottom: 2px solid rgb(100, 100, 100);
    content: "";
    display: inline-block;
    z-index: -105;
    left: 80px;
  }

  .one-last-tree:before {
    box-sizing: border-box;
    position: relative;
    height: 25px;
    width: 35px;
    color: white;
    border-left:2px solid rgb(100,100,100);
    border-bottom: 2px solid rgb(100, 100, 100);
    content: "";
    display: inline-block;
    z-index: -105;
  }

  .one-last-tree {
    margin: 0;
    line-height: 20px;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }


  .last-tree:before {
    box-sizing: border-box;
    border-left:2px solid rgb(100,100,100);
    position:relative;
    height: 25px;
    width:35px;
    color:white;
    border-bottom: 2px solid rgb(100,100,100);
    content:"";
    display:inline-block;
    z-index: -100;
  }

  #tableHeader:before {
    content:"";
    display:inline-block;
    z-index: -100;
  }

</style>
